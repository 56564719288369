<template>
  <div class="socialAccounts">
    <ul v-if='showMediaConnectButtons'>
      <li v-for="(mediaAccount, i) in mediaAccounts" :key="i + 1">
        <a
          :href="`${mediaAccount.oAuthLink}?callback=${origin + callbackUrl}/${
            mediaAccount.value
          }`"
          :class="isConnected(mediaAccount.value) ? 'change-social' : ''"
        >
          <img
            :src="`${$config.IMG_HOST}/20x20/${mediaAccount.logo}`"
            alt=""
            class="img-fluid"
          />
          {{ mediaAccount.text }}
        </a>
      </li>
    </ul>
    <infinite-loading
      @infinite="infiniteHandler"
      spinner="spiral"
      :identifier="infiniteId"
      :distance="30"
      v-if="isDataAddedUpdated"
    >
    </infinite-loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { MEDIA_ACCOUNT } from "@/graphql/user/mutations";
export default {
  props: {
    callbackUrl: String,
  },
  data() {
    return {
      infiniteId: +new Date(),
      isDataAddedUpdated: false,
      showMediaConnectButtons: true,
      origin: window.location.origin,
      mediaAccount: {
        account_id: 0,
        access_token: null,
        refresh_token: null,
        provider_user_id: null,
      },
    };
  },
  created() {
    //const params = Object.fromEntries(urlSearchParams.entries());
    if (this.$route.params.id) {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.mediaAccount.provider_user_id = params.get("raw[user_id]");
      this.mediaAccount.account_id = parseInt(this.$route.params.id);
      this.mediaAccount.access_token = this.$route.query.access_token;
      this.mediaAccount.refresh_token = this.$route.query.refresh_token;

      this.isDataAddedUpdated = true;
      this.showMediaConnectButtons = false;
      this.infiniteId += 1;
      
      this.updateMediaAccount();
    }
  },
  computed: {
    ...mapGetters({
      mediaAccounts: "mediaAccounts/list",
      influencerAccounts: "mediaAccounts/influencerAccounts",
    }),
  },
  methods: {
    ...mapActions("mediaAccounts", ["setMediaAccount"]),
    ...mapActions(["updateUser"]),
    onSubmit() {
      this.updateMediaAccount();
    },
    async updateMediaAccount() {
      await this.$apollo
        .mutate({
          mutation: MEDIA_ACCOUNT,
          variables: this.mediaAccount,
        })
        .then((data) => {
          if (data) {
            if (
              data.data.updateMediaAccount.state == "added" ||
              data.data.updateMediaAccount.state == "updated"
            ) {
              this.isDataAddedUpdated = false;
              this.showMediaConnectButtons = false;
              this.setMediaAccount();
              this.updateUser();
              this.$emit("success");
            }
          }
        })
        .catch((e) => {
          this.showMediaConnectButtons = true;
          this.isDataAddedUpdated = false;
          this.handleError(e);
        });
    },
    isConnected(id) {
      return this.influencerAccounts.some(function (el) {
        return el.id === id;
      });
    },
    infiniteHandler($state){},
  },
};
</script>

<style lang="scss" scopped>
.socialAccounts {
  margin-bottom: rem(10px);
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 0 0 32%;
      max-width: 32%;
      margin-bottom: rem(12px);
      font-size: 14px;
      border: 1px solid #caced5 !important;
      border-radius: 50px !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 5px 3px;
      text-decoration: none;
      min-width: auto !important;
      min-height: 65px !important;
      background-color: #fff !important;
      overflow: hidden;
      @media screen and (max-width: 991px) {
        min-height: 40px !important;
      }
      img {
        margin-right: 10px;
      }
      a {
        color: var(--textPrimary) !important;
        @include flex(center, center);
        // padding: 1.125rem 1.25rem !important;
        font-weight: 700;
        width: 100%;
        height: 100%;
        &.change-social {
          position: relative;
          &:hover {
            &:after {
              position: absolute;
              left: 0px;
              top: 0px;
              height: 100%;
              width: 100%;
              background: #e8e8f0;
              content: "Change";
              @include flex(center, center);
            }
          }
        }
      }
    }
  }
}
</style>
